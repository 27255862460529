.main-content-main-content {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
.main-content-container100 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/blockchain-shape.svg");
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  background-position: center;
  border-bottom-width: 0.5px;
}
.main-content-container101 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 80px;
  display: flex;
  position: absolute;
  box-shadow: 0px 5px 5px 0px #f3f4fe;
  align-items: center;
  border-color: rgba(213, 213, 213, 0.5);
  border-width: 1px;
  padding-left: 5%;
  padding-right: 5%;
  justify-content: space-between;
  background-color: #fff;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0.5px;
}
.main-content-container102 {
  gap: 10px;
  width: 310px;
  display: flex;
  align-items: center;
}
.main-content-container103 {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 4px;
  overflow: hidden;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 99.00%);
}
.main-content-container104 {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}
.main-content-image10 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-content-text100 {
  fill: #444f60;
  color: #444f60;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.main-content-container105 {
  gap: 30px;
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
}
.main-content-text101:hover {
  opacity: 1;
  font-weight: 600;
}
.main-content-link10 {
  text-decoration: none;
}
.main-content-link10:hover {
  opacity: 1;
  font-weight: 600;
}
.main-content-link11 {
  text-decoration: none;
}
.main-content-link11:hover {
  opacity: 1;
  font-weight: 600;
}
.main-content-link12 {
  text-decoration: none;
}
.main-content-link12:hover {
  opacity: 1;
  font-weight: 600;
}
.main-content-container106 {
  gap: 10px;
  flex: 0 0 auto;
  width: 310px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.main-content-link13 {
  display: contents;
}
.main-content-container107 {
  flex: 1.5;
  z-index: 100;
  box-shadow: 3px 3px 10px 0px #adadad;
  padding-left: 10px;
  border-radius: 24px;
  text-decoration: none;
}
.main-content-link14 {
  display: contents;
}
.main-content-container108 {
  z-index: 100;
  border-radius: 24px;
}
.main-content-container109 {
  flex: 0 0 auto;
  width: 200px;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container110 {
  flex: 0 0 auto;
  width: 200px;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container111 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 80px;
  flex-direction: column;
  padding-bottom: 2%;
}
.main-content-container112 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 10%;
}
.main-content-container113 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container114 {
  gap: 15px;
  flex: 1.5;
  height: 423px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.main-content-container115 {
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: center;
  padding-top: 1%;
  border-color: rgba(213, 213, 213, 0.3);
  border-width: 1px;
  padding-left: 2%;
  border-radius: 10px;
  padding-right: 2%;
  flex-direction: column;
  padding-bottom: 1%;
  background-color: #fff;
}
.main-content-image11 {
  width: 80px;
  object-fit: cover;
}
.main-content-container116 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.main-content-container117 {
  color: #fff;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: flex-start;
  padding-top: 3%;
  padding-left: 5%;
  border-radius: 15px;
  padding-right: 5%;
  flex-direction: column;
  padding-bottom: 3%;
  background-size: cover;
  justify-content: center;
  background-image: url("/hexagon.svg");
}
.main-content-text105 {
  font-size: 16px;
  margin-bottom: 0px;
}
.main-content-link15 {
  display: contents;
}
.main-content-container118 {
  gap: 20px;
  width: auto;
  display: none;
  box-shadow: 5px 5px 10px 0px rgba(212, 212, 212, 0);
  border-color: #ffffff;
  border-width: 2px;
  padding-left: 30px;
  border-radius: 24px;
  padding-right: 10px;
  flex-direction: row;
  text-decoration: none;
}
.main-content-container119 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #3c1c6b;
}
.main-content-icon17 {
  width: 20px;
  height: 20px;
}
.main-content-container120 {
  gap: 10px;
  flex: 0 0 auto;
  width: 507px;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-top: 5px;
  border-color: var(--dl-color-primary-lila);
  border-width: 1px;
  padding-left: 20px;
  border-radius: 30px;
  padding-right: 5px;
  animation-name: slideInUp;
  flex-direction: row;
  padding-bottom: 5px;
  animation-delay: 0s;
  justify-content: flex-start;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.main-content-textinput1 {
  flex: 1.8;
  height: 100%;
}
.main-content-link16 {
  display: contents;
}
.main-content-container121 {
  width: 150px;
  z-index: 100;
  border-radius: 24px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.main-content-container122 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.main-content-container123 {
  flex: 1;
  border-radius: 24px;
  text-decoration: none;
}
.main-content-container124 {
  z-index: 100;
  border-radius: 24px;
}
.main-content-container125 {
  flex: 0 0 auto;
  width: 510px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-content-container126 {
  color: #fff;
  display: flex;
  align-items: center;
  padding-top: 3%;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 5%;
  border-radius: 15px;
  padding-right: 5%;
  padding-bottom: 3%;
  background-size: cover;
  justify-content: center;
  background-image: url("/hexagon.svg");
}
.main-content-text110 {
  text-align: left;
}
.main-content-container127 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: contain;
  background-image: url("/blockchain-hero-1-core-1500h.png");
  background-repeat: no-repeat;
  background-position: center;
}
.main-content-image12 {
  width: 100%;
  height: 100%;
  display: none;
  object-fit: cover;
}
.main-content-container128 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-content-link19 {
  display: contents;
}
.main-content-container129 {
  display: flex;
  padding: 10px;
  align-items: center;
  border-color: rgba(213, 213, 213, 0.4);
  border-width: 1px;
  border-radius: 15px;
  justify-content: center;
  text-decoration: none;
  background-color: #fff;
}
.main-content-container130 {
  gap: 10px;
  fill: var(--dl-color-primary-lila);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column-reverse;
  justify-content: center;
}
.main-content-text111 {
  font-size: 12px;
}
.main-content-container131 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  flex-direction: column-reverse;
  padding-bottom: 10px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.main-content-icon19 {
  color: var(--dl-color-primary-lila);
}
.main-content-container132 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.main-content-link20 {
  text-decoration: none;
}
.main-content-link21 {
  text-decoration: none;
}
.main-content-link22 {
  text-decoration: none;
}
.main-content-link23 {
  text-decoration: none;
}
.main-content-container133 {
  gap: 60px;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  padding-top: 5%;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: column;
  padding-bottom: 5%;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0.5px;
}
.main-content-container134 {
  gap: 80px;
  color: #fff;
  width: 100%;
  height: auto;
  display: flex;
  box-shadow: 0px 0px 30px 0px #b0b2be;
  align-items: center;
  padding-top: 2%;
  padding-left: 5%;
  border-radius: 55px;
  margin-bottom: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
  background-size: cover;
  background-image: url("/animated%20shape%20(1).svg");
}
.main-content-container135 {
  gap: 40px;
  flex: 0 0 auto;
  width: 526px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container136 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container137 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.main-content-text112 {
  font-size: 38px;
  font-family: "Open Sans";
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 1;
  text-transform: none;
  text-decoration: none;
}
.main-content-text113 {
  font-style: normal;
  font-weight: 300;
}
.main-content-link24 {
  display: contents;
}
.main-content-container138 {
  gap: 20px;
  width: auto;
  box-shadow: 5px 5px 10px 0px rgba(212, 212, 212, 0);
  border-color: #ffffff;
  border-width: 2px;
  padding-left: 30px;
  border-radius: 24px;
  padding-right: 10px;
  flex-direction: row;
  text-decoration: none;
}
.main-content-container139 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #3c1c6b;
}
.main-content-icon23 {
  width: 20px;
  height: 20px;
}
.main-content-container140 {
  gap: 20px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container141 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: 5%;
  align-items: flex-start;
  padding-left: 5%;
  border-radius: 25px;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.17);
}
.main-content-container142 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container143 {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}
.main-content-image13 {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.main-content-text121 {
  opacity: 0.7;
  font-style: normal;
  font-weight: 300;
  line-height: 1.6;
  padding-left: 70px;
}
.main-content-container144 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: 5%;
  align-items: flex-start;
  border-radius: 25px;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.17);
}
.main-content-container145 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container146 {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}
.main-content-image14 {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.main-content-text123 {
  opacity: 0.7;
  font-style: normal;
  font-weight: 300;
  line-height: 1.6;
  padding-left: 70px;
}
.main-content-container147 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-content-text124 {
  font-size: 38px;
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 1;
  text-transform: none;
  text-decoration: none;
}
.main-content-text126 {
  color: var(--dl-color-primary-lila);
  font-weight: 700;
}
.main-content-text127 {
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.main-content-container148 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container149 {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: flex-start;
}
.main-content-container150 {
  gap: 20px;
  flex: 1;
  width: 200px;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
}
.main-content-container150:hover {
  color: var(--dl-color-primary-lila);
}
.main-content-container151 {
  flex: 0 0 auto;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-image15 {
  width: 100%;
  filter: invert(8%) sepia(30%) saturate(700%) hue-rotate(-10deg) brightness(47%);
  object-fit: cover;
}
.main-content-text128 {
  fill: var(--dl-color-primary-lila);
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text129 {
  opacity: 0.8;
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.main-content-container152 {
  gap: 20px;
  flex: 1;
  width: 200px;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
}
.main-content-container152:hover {
  color: var(--dl-color-primary-lila);
}
.main-content-container153 {
  flex: 0 0 auto;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-image16 {
  width: 100%;
  filter: invert(8%) sepia(30%) saturate(700%) hue-rotate(-10deg) brightness(47%);
  object-fit: cover;
}
.main-content-text130 {
  fill: var(--dl-color-primary-lila);
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text131 {
  opacity: 0.8;
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.main-content-container154 {
  gap: 20px;
  flex: 1;
  width: 200px;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: center;
}
.main-content-container154:hover {
  color: var(--dl-color-primary-lila);
}
.main-content-container155 {
  flex: 0 0 auto;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-image17 {
  width: 100%;
  filter: invert(8%) sepia(30%) saturate(700%) hue-rotate(-10deg) brightness(47%);
  object-fit: cover;
}
.main-content-text132 {
  fill: var(--dl-color-primary-lila);
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text133 {
  opacity: 0.8;
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.main-content-container156 {
  gap: 20px;
  flex: 1;
  width: 200px;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.1s;
  align-items: center;
  padding-left: 20px;
  flex-direction: column;
  justify-content: center;
}
.main-content-container156:hover {
  color: var(--dl-color-primary-lila);
}
.main-content-container157 {
  flex: 0 0 auto;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-image18 {
  width: 100%;
  filter: invert(8%) sepia(30%) saturate(700%) hue-rotate(-10deg) brightness(47%);
  object-fit: cover;
}
.main-content-text134 {
  fill: var(--dl-color-primary-lila);
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text135 {
  opacity: 0.8;
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.main-content-container158 {
  flex: 0 0 auto;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: flex-start;
}
.main-content-container159 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container160 {
  flex: 1;
  width: 200px;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgba(189, 195, 199, 0) 1.00%,rgb(213, 213, 213) 100.00%);
}
.main-content-container161 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.main-content-container162 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.main-content-container163 {
  flex: 1;
  width: 200px;
  height: 1px;
  display: flex;
  align-items: flex-start;
  padding-right: 20px;
  flex-direction: column;
  background-color: var(--dl-color-traffls-border);
}
.main-content-container164 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container165 {
  flex: 1;
  width: 200px;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-traffls-border);
}
.main-content-container166 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.main-content-container167 {
  flex: 0 0 auto;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.main-content-container168 {
  flex: 1;
  width: 200px;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-traffls-border);
}
.main-content-container169 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container170 {
  flex: 1;
  width: 200px;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-traffls-border);
}
.main-content-container171 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.main-content-container172 {
  flex: 0 0 auto;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.main-content-container173 {
  flex: 1;
  width: 200px;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-traffls-border);
}
.main-content-container174 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container175 {
  flex: 1;
  width: 200px;
  height: 1px;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  flex-direction: column;
  background-color: var(--dl-color-traffls-border);
}
.main-content-container176 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.main-content-container177 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-primary-lila);
}
.main-content-container178 {
  flex: 1;
  width: 200px;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgb(213, 213, 213) 0.00%,rgba(213, 213, 213, 0) 100.00%);
}
.main-content-container179 {
  gap: 40px;
  width: 100%;
  display: flex;
  z-index: 101;
  box-shadow: 0px 0px 25px 0px rgba(120, 113, 126, 0.15);
  align-items: flex-start;
  padding-top: 5%;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
  background-size: cover;
  justify-content: center;
  background-image: url("/animated%20shape%20(3).svg");
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0.5px;
}
.main-content-container180 {
  gap: 40px;
  width: 572px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.main-content-container181 {
  gap: 20px;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  flex-direction: column;
}
.main-content-text136 {
  font-size: 38px;
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 1;
  text-transform: none;
  text-decoration: none;
}
.main-content-text138 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text140 {
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.main-content-link25 {
  display: contents;
}
.main-content-container183 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #3c1c6b;
}
.main-content-icon25 {
  width: 20px;
  height: 20px;
}
.main-content-container184 {
  gap: 60px;
  width: 100%;
  display: flex;
  z-index: 100;
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: row-reverse;
  padding-bottom: 9%;
  background-size: contain;
  background-image: url("/bg-1500w.png");
  background-position: top;
}
.main-content-container185 {
  flex: 1;
  width: 564px;
  height: 330px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.main-content-container186 {
  gap: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.main-content-text145 {
  font-size: 38px;
  font-family: "Open Sans";
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 1;
  text-transform: none;
  text-decoration: none;
}
.main-content-text146 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text148 {
  font-style: normal;
  text-align: right;
  font-weight: 300;
}
.main-content-link26 {
  display: contents;
}
.main-content-container187 {
  gap: 20px;
  width: auto;
  padding-left: 30px;
  border-radius: 24px;
  padding-right: 10px;
  flex-direction: row;
  text-decoration: none;
}
.main-content-container188 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #3c1c6b;
}
.main-content-icon27 {
  width: 20px;
  height: 20px;
}
.main-content-container189 {
  gap: 60px;
  flex: 1;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
}
.main-content-container190 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-content-container191 {
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  display: flex;
  padding: 7%;
  overflow: hidden;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 99.00%);
}
.main-content-container192 {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}
.main-content-image19 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-content-container193 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-content-text155 {
  fill: #444f60;
  color: #444f60;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.main-content-text156 {
  font-style: normal;
  font-weight: 300;
}
.main-content-container194 {
  gap: 40px;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.main-content-container195 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container196 {
  gap: 10px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container197 {
  fill: var(--dl-color-theme-secondary1);
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 99.00%);
}
.main-content-container198 {
  gap: 10px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container199 {
  fill: var(--dl-color-primary-lila);
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 99.00%);
}
.main-content-container200 {
  gap: 10px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container201 {
  fill: var(--dl-color-primary-lila);
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 99.00%);
}
.main-content-container202 {
  gap: 20px;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container203 {
  gap: 10px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container204 {
  fill: var(--dl-color-primary-lila);
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 99.00%);
}
.main-content-container205 {
  gap: 10px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-content-container206 {
  fill: var(--dl-color-primary-lila);
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 98.00%);
}
.main-content-container207 {
  gap: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 2%;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: column;
  padding-bottom: 5%;
  background-color: #f5f6fa;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.main-content-text162 {
  font-size: 38px;
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 1;
  text-transform: none;
  text-decoration: none;
}
.main-content-text163 {
  color: #444f60;
  font-weight: 300;
}
.main-content-text164 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text165 {
  color: #444f60;
  font-weight: 300;
}
.main-content-text166 {
  color: #444f60;
  font-weight: 300;
}
.main-content-text167 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-container208 {
  gap: 40px;
  width: 959px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container209 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.main-content-container210 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  box-shadow: 0px 0px 10px 0px #e0e2e9;
  align-items: center;
  padding-top: 5%;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 40px;
  border-radius: 55px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: 5%;
  background-color: #fff;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.main-content-container211 {
  display: flex;
  padding: 5%;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 100.00%);
}
.main-content-container212 {
  flex: 0 0 auto;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(90deg, rgb(155, 116, 215) 0.00%,rgb(104, 64, 165) 100.00%);
}
.main-content-text169 {
  fill: #444f60;
  color: #444f60;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.main-content-text170 {
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.main-content-container213 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  box-shadow: 0px 0px 10px 0px #e0e2e9;
  align-items: center;
  padding-top: 5%;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 40px;
  border-radius: 55px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: 5%;
  background-color: #fff;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.main-content-container214 {
  display: flex;
  padding: 5%;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 99.00%);
}
.main-content-container215 {
  flex: 0 0 auto;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(90deg, rgb(155, 116, 215) 0.00%,rgb(104, 64, 165) 100.00%);
}
.main-content-text172 {
  fill: #444f60;
  color: #444f60;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.main-content-text173 {
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.main-content-container216 {
  gap: 40px;
  width: 100%;
  height: 285px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.main-content-container217 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  box-shadow: 0px 0px 10px 0px #e0e2e9;
  align-items: center;
  padding-top: 5%;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 40px;
  border-radius: 55px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: 5%;
  background-color: #fff;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.main-content-container218 {
  display: flex;
  padding: 5%;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 99.00%);
}
.main-content-container219 {
  flex: 0 0 auto;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(90deg, rgb(155, 116, 215) 0.00%,rgb(104, 64, 165) 100.00%);
}
.main-content-text175 {
  fill: #444f60;
  color: #444f60;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.main-content-text176 {
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.main-content-container220 {
  gap: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  box-shadow: 0px 0px 10px 0px #e0e2e9;
  align-items: center;
  padding-top: 5%;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 40px;
  border-radius: 55px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: 5%;
  background-color: #fff;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.main-content-container221 {
  display: flex;
  padding: 5%;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-image: linear-gradient(45deg, rgba(189, 195, 199, 0) 0.00%,rgba(75, 10, 136, 0.3) 99.00%);
}
.main-content-container222 {
  flex: 0 0 auto;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(90deg, rgb(155, 116, 215) 0.00%,rgb(104, 64, 165) 100.00%);
}
.main-content-text178 {
  fill: #444f60;
  color: #444f60;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.main-content-text179 {
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.main-content-container223 {
  gap: 20px;
  color: #fff;
  width: 100%;
  display: flex;
  z-index: 100;
  box-shadow: 0px 0px 25px 0px rgba(120, 113, 126, 0.35);
  align-items: center;
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: column;
  padding-bottom: 5%;
  background-size: cover;
  background-image: url("/animated%20shape.svg");
}
.main-content-container224 {
  display: flex;
  padding: 1%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: center;
  border-color: rgba(213, 213, 213, 0.3);
  border-width: 1px;
  border-radius: 15px;
  flex-direction: column;
  background-color: #fff;
}
.main-content-image20 {
  width: 60px;
  object-fit: cover;
}
.main-content-container225 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-content-text180 {
  font-size: 38px;
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 1;
  text-transform: none;
  text-decoration: none;
}
.main-content-text181 {
  font-style: normal;
  font-weight: 300;
}
.main-content-text183 {
  font-style: normal;
  font-weight: 700;
}
.main-content-text184 {
  font-size: 16px;
  text-align: center;
  font-weight: 300;
  letter-spacing: 1px;
}
.main-content-text185 {
  font-style: normal;
}
.main-content-text186 {
  font-style: normal;
}
.main-content-text187 {
  font-style: normal;
}
.main-content-text188 {
  font-weight: 700;
}
.main-content-text189 {
  font-weight: 700;
}
.main-content-container226 {
  gap: 10px;
  flex: 0 0 auto;
  width: 510px;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-top: 0px;
  border-color: rgba(213, 213, 213, 0.3);
  border-width: 1px;
  padding-left: 20px;
  border-radius: 30px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0.47);
}
.main-content-textinput2 {
  flex: 1.8;
  height: 100%;
  background-color: transparent;
}
.main-content-link27 {
  display: contents;
}
.main-content-container227 {
  gap: 10px;
  color: var(--dl-color-primary-lila);
  width: 150px;
  height: 48px;
  display: flex;
  z-index: 100;
  box-shadow: 5px 5px 10px 0px rgba(212, 212, 212, 0);
  align-items: center;
  border-radius: 0px;
  justify-content: center;
  background-color: #fff;
}
.main-content-container228 {
  fill: var(--dl-color-primary-lila);
  flex: 0 0 auto;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.main-content-icon39 {
  width: 15px;
  height: 15px;
}
.main-content-container229 {
  flex: 0 0 auto;
  width: 510px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-content-text191 {
  text-align: center;
}
.main-content-container230 {
  gap: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #f5f6fa;
}
.main-content-container231 {
  gap: 40px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-text192 {
  font-size: 38px;
}
.main-content-text195 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text196 {
  display: inline-block;
}
.main-content-text197 {
  display: inline-block;
}
.main-content-text198 {
  display: inline-block;
}
.main-content-text199 {
  display: inline-block;
}
.main-content-text200 {
  display: inline-block;
}
.main-content-text201 {
  display: inline-block;
}
.main-content-text202 {
  display: inline-block;
}
.main-content-text203 {
  display: inline-block;
}
.main-content-text204 {
  display: inline-block;
}
.main-content-text205 {
  display: inline-block;
}
.main-content-text206 {
  display: inline-block;
}
.main-content-text207 {
  display: inline-block;
}
.main-content-text208 {
  display: inline-block;
}
.main-content-text209 {
  display: inline-block;
}
.main-content-text210 {
  display: inline-block;
}
.main-content-text211 {
  display: inline-block;
}
.main-content-container232 {
  gap: 20px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container233 {
  gap: 40px;
  color: #fff;
  width: 100%;
  display: flex;
  padding: 5%;
  box-shadow: 0px 0px 10px 0px #e0e2e9;
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-style: solid;
  border-width: 1px;
  border-radius: 55px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/hexagon.svg");
}
.main-content-text212 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}
.main-content-text213 {
  text-align: center;
}
.main-content-link28 {
  display: contents;
}
.main-content-container234 {
  gap: 20px;
  width: auto;
  box-shadow: 5px 5px 10px 0px rgba(212, 212, 212, 0);
  border-color: #ffffff;
  border-width: 2px;
  padding-left: 30px;
  border-radius: 24px;
  padding-right: 10px;
  flex-direction: row;
  text-decoration: none;
}
.main-content-container235 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #3c1c6b;
}
.main-content-icon41 {
  width: 20px;
  height: 20px;
}
.main-content-container236 {
  gap: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 11%;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: column;
  padding-bottom: 5%;
  background-size: contain;
  background-image: url("/bg%20(1)-1500w.png");
  border-top-width: 0px;
  background-repeat: no-repeat;
  border-left-width: 0px;
  border-right-width: 0px;
  background-position: top;
  border-bottom-width: 0.5px;
}
.main-content-container237 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-content-text215 {
  font-size: 38px;
  font-family: "Open Sans";
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 1;
  text-transform: none;
  text-decoration: none;
}
.main-content-text217 {
  color: var(--dl-color-primary-lila);
  font-weight: 700;
}
.main-content-text218 {
  font-style: normal;
  font-weight: 300;
}
.main-content-container238 {
  gap: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.main-content-container239 {
  gap: 40px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.main-content-container240 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.main-content-container241 {
  gap: 10px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container242 {
  width: 100%;
  height: 42px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: column;
  background-color: #fff;
}
.main-content-textinput3 {
  width: 100%;
  height: 100%;
}
.main-content-container243 {
  gap: 10px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container244 {
  width: 100%;
  height: 42px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: column;
  background-color: #fff;
}
.main-content-textinput4 {
  width: 100%;
  height: 100%;
}
.main-content-container245 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.main-content-container246 {
  gap: 10px;
  flex: 1;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container247 {
  width: 100%;
  height: 42px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: column;
  background-color: #fff;
}
.main-content-textinput5 {
  width: 100%;
  height: 100%;
}
.main-content-container248 {
  gap: 10px;
  flex: 1;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container249 {
  width: 100%;
  height: 42px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: column;
  background-color: #fff;
}
.main-content-textinput6 {
  width: 100%;
  height: 100%;
}
.main-content-container250 {
  gap: 10px;
  flex: 1;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-text223 {
  font-style: normal;
  font-weight: 700;
}
.main-content-container251 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: #fff;
}
.main-content-textarea {
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 10px;
  border-width: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: transparent;
}
.main-content-container252 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-content-container253 {
  gap: 20px;
  width: 261px;
  box-shadow: 5px 5px 10px 0px rgba(212, 212, 212, 0);
  transition: 0.1s;
  padding-left: 30px;
  border-radius: 24px;
  padding-right: 30px;
  flex-direction: row;
}
.main-content-container253:hover {
  width: 281px;
  padding-left: 40px;
  padding-right: 40px;
}
.main-content-text225 {
  color: #096909;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.main-content-container254 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 4%;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: column;
  padding-bottom: 2%;
  background-color: #fafbfd;
}
.main-content-container255 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-content-text226 {
  font-size: 38px;
  font-family: "Open Sans";
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 1;
  text-transform: none;
  text-decoration: none;
}
.main-content-text228 {
  color: var(--dl-color-primary-lila);
  font-weight: 700;
}
.main-content-text229 {
  font-style: normal;
  font-weight: 300;
}
.main-content-container256 {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container257 {
  width: 100%;
}
.main-content-container258 {
  display: contents;
}
.main-content-container259 {
  gap: 20px;
  color: #fff;
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: row;
  padding-bottom: 40px;
  background-size: cover;
  background-image: url("/rect%20light.svg");
}
.main-content-container260 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container261 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.main-content-container262 {
  gap: 20px;
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container263 {
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.main-content-image21 {
  width: auto;
  height: 20px;
  z-index: 100;
  object-fit: cover;
}
.main-content-text230 {
  font-style: normal;
  font-weight: 300;
}
.main-content-container264 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.main-content-link29 {
  display: contents;
}
.main-content-container265 {
  fill: var(--dl-color-primary-lila);
  flex: 0 0 auto;
  width: 30px;
  cursor: pointer;
  height: 30px;
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.main-content-icon43 {
  width: 100%;
  height: 100%;
}
.main-content-link30 {
  display: contents;
}
.main-content-container266 {
  fill: var(--dl-color-primary-lila);
  flex: 0 0 auto;
  width: 30px;
  cursor: pointer;
  height: 30px;
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.main-content-icon45 {
  width: 100%;
  height: 100%;
}
.main-content-container267 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.main-content-container268 {
  gap: 30px;
  flex: 0 0 auto;
  color: var(--dl-color-primary-hell);
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container269 {
  cursor: pointer;
  display: flex;
  opacity: 0.6;
  transition: 0.1s;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container269:hover {
  opacity: 1;
}
.main-content-link31 {
  text-decoration: none;
}
.main-content-link31:hover {
  opacity: 1;
}
.main-content-container270 {
  cursor: pointer;
  display: flex;
  opacity: 0.6;
  transition: 0.1s;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container270:hover {
  opacity: 1;
}
.main-content-link32 {
  text-decoration: none;
}
.main-content-link32:hover {
  opacity: 1;
}
.main-content-container271 {
  cursor: pointer;
  display: flex;
  opacity: 0.6;
  transition: 0.1s;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container271:hover {
  opacity: 1;
}
.main-content-link33 {
  text-decoration: none;
}
.main-content-link33:hover {
  opacity: 1;
}
.main-content-container272 {
  cursor: pointer;
  display: flex;
  opacity: 0.6;
  transition: 0.1s;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-container272:hover {
  opacity: 1;
}
.main-content-link34 {
  text-decoration: none;
}
.main-content-link34:hover {
  opacity: 1;
}
.main-content-container273 {
  gap: 30px;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-navlink1 {
  display: contents;
}
.main-content-container274 {
  cursor: pointer;
  display: flex;
  opacity: 0.6;
  transition: 0.1s;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.main-content-container274:hover {
  opacity: 1;
}
.main-content-text233:hover {
  opacity: 1;
}
.main-content-navlink2 {
  display: contents;
}
.main-content-container275 {
  cursor: pointer;
  display: flex;
  opacity: 0.6;
  transition: 0.1s;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.main-content-container275:hover {
  opacity: 1;
}
.main-content-text234:hover {
  opacity: 1;
}
.main-content-container276 {
  gap: 30px;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-link35 {
  display: contents;
}
.main-content-container277 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.main-content-container278 {
  flex: 0 0 auto;
  color: #fff;
  width: 100%;
  display: none;
  align-items: flex-start;
}
.main-content-container279 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.main-content-container280 {
  display: flex;
  align-items: flex-start;
}
.main-content-container281 {
  gap: 30px;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-text246 {
  font-size: 16px;
}
.main-content-container282 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.main-content-link36 {
  display: contents;
}
.main-content-container283 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.main-content-container284 {
  display: flex;
  align-items: flex-start;
}
.main-content-container285 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.main-content-link37 {
  display: contents;
}
.main-content-container286 {
  fill: var(--dl-color-primary-lila);
  flex: 0 0 auto;
  width: 30px;
  cursor: pointer;
  height: 30px;
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.main-content-icon47 {
  width: 100%;
  height: 100%;
}
.main-content-link38 {
  display: contents;
}
.main-content-container287 {
  fill: var(--dl-color-primary-lila);
  flex: 0 0 auto;
  width: 30px;
  cursor: pointer;
  height: 30px;
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.main-content-icon49 {
  width: 100%;
  height: 100%;
}
.main-content-text255 {
  display: inline-block;
}
.main-content-text256 {
  fill: rgb(153, 153, 153);
  color: rgb(153, 153, 153);
  display: inline-block;
  letter-spacing: 1px;
}
.main-content-text257 {
  cursor: pointer;
  display: inline-block;
  transition: 0.1s;
}
.main-content-text257:hover {
  opacity: 1;
}
.main-content-text258 {
  display: inline-block;
}
.main-content-text259 {
  fill: #444f60;
  color: rgb(68, 79, 96);
  display: inline-block;
  font-size: 3.5rem;
  line-height: 1.2em;
  margin-bottom: 4.525856562845201%;
  animation-name: slideInLeft;
  letter-spacing: 1.2px;
  animation-delay: 0s;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.main-content-text260 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text264 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text268 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.main-content-text269 {
  display: inline-block;
}
.main-content-text270 {
  display: inline-block;
  font-size: 22px;
}
.main-content-text271 {
  display: inline-block;
}
.main-content-text272 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.main-content-text275 {
  cursor: pointer;
  display: inline-block;
  transition: 0.1s;
}
.main-content-text275:hover {
  opacity: 1;
}
.main-content-text276 {
  display: inline-block;
  font-size: 22px;
}
.main-content-text277 {
  display: inline-block;
}
.main-content-text278 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.main-content-text281 {
  display: inline-block;
}
.main-content-text281:hover {
  opacity: 1;
}
.main-content-text282 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
}
.main-content-text283 {
  display: inline-block;
  font-size: 38px;
  font-family: "Open Sans";
  line-height: 1.6;
  text-transform: none;
  text-decoration: none;
}
.main-content-text286 {
  font-weight: 700;
}
.main-content-text287 {
  display: inline-block;
}
.main-content-text288 {
  display: inline-block;
}
.main-content-text289 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
}
.main-content-text290 {
  display: inline-block;
}
.main-content-text291 {
  display: inline-block;
}
.main-content-text291:hover {
  opacity: 1;
}
.main-content-text292 {
  cursor: pointer;
  display: inline-block;
  transition: 0.1s;
}
.main-content-text292:hover {
  opacity: 1;
}
.main-content-text293 {
  display: inline-block;
}
.main-content-text294 {
  display: inline-block;
}
.main-content-text295 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
}
.main-content-text296 {
  display: inline-block;
  font-size: 22px;
}
.main-content-text297 {
  display: inline-block;
}
.main-content-text298 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
}
.main-content-text299 {
  display: inline-block;
  font-size: 22px;
}
.main-content-text300 {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.main-content-text301 {
  display: inline-block;
}
.main-content-text302 {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.main-content-text303 {
  display: inline-block;
}
.main-content-text304 {
  display: inline-block;
}
.main-content-text305 {
  display: inline-block;
}
.main-content-text306 {
  display: inline-block;
}
@media(max-width: 479px) {
  .main-content-container100 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .main-content-container101 {
    z-index: 105;
  }
  .main-content-container103 {
    padding: 4px;
    border-radius: 10px;
  }
  .main-content-container104 {
    border-radius: 10px;
  }
  .main-content-text100 {
    display: none;
  }
  .main-content-container105 {
    display: none;
  }
  .main-content-container106 {
    display: none;
  }
  .main-content-container109 {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-content-container110 {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-content-container111 {
    padding-bottom: 20%;
  }
  .main-content-container112 {
    flex: 0 0 auto;
    height: 100%;
    padding-right: 10%;
    flex-direction: column-reverse;
  }
  .main-content-container113 {
    flex: 0 0 auto;
    width: 100%;
  }
  .main-content-container114 {
    gap: 30px;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    align-items: center;
    padding-top: 10%;
    justify-content: flex-start;
  }
  .main-content-container115 {
    align-items: center;
    padding-top: 2%;
    padding-left: 3%;
    border-radius: 10px;
    padding-right: 3%;
    padding-bottom: 2%;
    justify-content: center;
  }
  .main-content-text104 {
    text-align: center;
  }
  .main-content-container116 {
    gap: 10px;
    width: 100%;
  }
  .main-content-container117 {
    gap: 20px;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    padding-top: 4%;
    padding-bottom: 4%;
  }
  .main-content-text105 {
    text-align: center;
  }
  .main-content-container118 {
    width: 100%;
    display: flex;
  }
  .main-content-container120 {
    width: 100%;
    padding-top: 3px;
    border-width: 2px;
    border-radius: 15px;
    padding-right: 3px;
    padding-bottom: 3px;
  }
  .main-content-textinput1 {
    background-color: transparent;
  }
  .main-content-container121 {
    flex: 1;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
  }
  .main-content-container127 {
    width: 100%;
    height: 260px;
    display: none;
  }
  .main-content-container128 {
    display: none;
  }
  .main-content-container132 {
    gap: 20px;
    flex: 1;
    z-index: 102;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #fbfbfb;
  }
  .main-content-container133 {
    padding-bottom: 10%;
  }
  .main-content-container134 {
    gap: 20px;
    padding-top: 5%;
    margin-bottom: 0%;
    flex-direction: column;
    padding-bottom: 5%;
  }
  .main-content-container135 {
    width: 100%;
    align-items: center;
  }
  .main-content-container136 {
    width: 100%;
    align-items: center;
  }
  .main-content-text112 {
    font-size: 28px;
    text-align: center;
  }
  .main-content-text113 {
    font-size: 16px;
    text-align: center;
  }
  .main-content-container141 {
    gap: 10px;
    align-items: center;
    flex-direction: column;
  }
  .main-content-container142 {
    flex-direction: column;
  }
  .main-content-text121 {
    text-align: center;
    padding-left: 0px;
  }
  .main-content-container144 {
    gap: 10px;
    align-items: center;
  }
  .main-content-container145 {
    flex-direction: column;
  }
  .main-content-text123 {
    text-align: center;
    padding-left: 0px;
  }
  .main-content-text124 {
    font-size: 28px;
  }
  .main-content-text127 {
    font-size: 16px;
  }
  .main-content-container149 {
    gap: 40px;
    height: auto;
    flex-direction: column;
  }
  .main-content-container150 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    padding-right: 0px;
  }
  .main-content-text129 {
    font-size: 16px;
  }
  .main-content-container152 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .main-content-text131 {
    font-size: 16px;
  }
  .main-content-container154 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .main-content-text133 {
    font-size: 16px;
  }
  .main-content-container156 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    padding-left: 0px;
  }
  .main-content-text135 {
    font-size: 16px;
  }
  .main-content-container158 {
    display: none;
  }
  .main-content-text136 {
    font-size: 28px;
  }
  .main-content-text140 {
    font-size: 16px;
  }
  .main-content-container184 {
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .main-content-container185 {
    gap: 40px;
    flex: 0 0 auto;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .main-content-container186 {
    align-items: center;
  }
  .main-content-text145 {
    font-size: 28px;
  }
  .main-content-text148 {
    font-size: 16px;
    text-align: center;
  }
  .main-content-container191 {
    display: none;
  }
  .main-content-text156 {
    font-size: 16px;
  }
  .main-content-container194 {
    gap: 20px;
    width: 100%;
  }
  .main-content-text157 {
    font-size: 14px;
  }
  .main-content-text158 {
    font-size: 14px;
  }
  .main-content-text159 {
    font-size: 14px;
  }
  .main-content-text160 {
    font-size: 14px;
  }
  .main-content-text161 {
    font-size: 14px;
  }
  .main-content-container207 {
    height: 1450px;
    padding-bottom: 10%;
  }
  .main-content-text162 {
    font-size: 28px;
  }
  .main-content-container208 {
    width: 100%;
  }
  .main-content-container209 {
    flex-direction: column;
  }
  .main-content-container210 {
    border-radius: 40px;
  }
  .main-content-text170 {
    font-size: 16px;
  }
  .main-content-container213 {
    border-radius: 40px;
    border-top-left-radius: 55px;
    border-bottom-left-radius: 55px;
  }
  .main-content-text173 {
    font-size: 16px;
  }
  .main-content-container216 {
    flex-direction: column;
  }
  .main-content-container217 {
    border-radius: 40px;
    border-top-right-radius: 55px;
    border-bottom-right-radius: 55px;
  }
  .main-content-text176 {
    font-size: 16px;
  }
  .main-content-container220 {
    border-radius: 40px;
    border-top-left-radius: 55px;
    border-bottom-left-radius: 55px;
  }
  .main-content-text179 {
    font-size: 16px;
  }
  .main-content-container223 {
    gap: 40px;
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .main-content-container224 {
    width: 80px;
    height: 30px;
  }
  .main-content-text180 {
    font-size: 28px;
  }
  .main-content-text184 {
    font-size: 16px;
  }
  .main-content-container226 {
    width: 100%;
  }
  .main-content-container230 {
    flex-direction: column;
    padding-bottom: 10%;
  }
  .main-content-text192 {
    font-size: 28px;
    text-align: center;
  }
  .main-content-text193 {
    font-size: 28px;
    font-family: Open Sans;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 1px;
    text-transform: none;
    text-decoration: none;
  }
  .main-content-text195 {
    color: var(--dl-color-primary-lila);
    font-style: normal;
    font-weight: 700;
  }
  .main-content-container233 {
    padding-left: 7%;
    padding-right: 7%;
  }
  .main-content-text212 {
    text-align: center;
  }
  .main-content-container236 {
    gap: 20px;
    padding-bottom: 10%;
  }
  .main-content-text215 {
    font-size: 28px;
  }
  .main-content-text218 {
    font-size: 16px;
    text-align: center;
  }
  .main-content-container238 {
    height: 483px;
    flex-direction: column;
  }
  .main-content-container254 {
    gap: 40px;
    height: 1080px;
    padding-bottom: 10%;
  }
  .main-content-text226 {
    font-size: 28px;
    text-align: center;
  }
  .main-content-text229 {
    font-size: 16px;
    text-align: center;
  }
  .main-content-container256 {
    height: 836px;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-color: var(--dl-color-traffls-border);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    background-color: #fff;
  }
  .main-content-container259 {
    display: none;
  }
  .main-content-container278 {
    gap: 40px;
    display: flex;
    align-items: center;
    padding-top: 70px;
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: column;
    padding-bottom: 100px;
    background-size: cover;
    background-image: url("/rect%20light.svg");
    background-position: top;
  }
  .main-content-text243 {
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
  }
  .main-content-container279 {
    gap: 60px;
    height: 400px;
    align-items: center;
    flex-direction: column;
  }
  .main-content-container280 {
    gap: 40px;
    opacity: 0.7;
    align-items: center;
    flex-direction: column;
  }
  .main-content-container281 {
    gap: 20px;
    height: auto;
    align-items: center;
  }
  .main-content-text246 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  .main-content-container282 {
    gap: 20px;
    opacity: 0.7;
    align-items: center;
  }
  .main-content-text248 {
    text-align: center;
  }
  .main-content-container284 {
    gap: 20px;
    align-items: center;
    flex-direction: column;
  }
  .main-content-text254 {
    text-align: center;
  }
  .main-content-text283 {
    font-size: 28px;
  }
  .main-content-text284 {
    font-size: 28px;
    font-family: Open Sans;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 1px;
    text-transform: none;
    text-decoration: none;
  }
  .main-content-text286 {
    font-weight: 700;
  }
}
