.home-container {
  width: 100%;
  display: flex;
  overflow: scroll;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
.home-text10 {
  display: inline-block;
}
.home-text11 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.home-text14 {
  display: inline-block;
}
.home-text15 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.home-text18 {
  display: inline-block;
}
.home-text19 {
  cursor: pointer;
  display: inline-block;
  transition: 0.1s;
}
.home-text19:hover {
  opacity: 1;
}
.home-text20 {
  cursor: pointer;
  display: inline-block;
  transition: 0.1s;
}
.home-text20:hover {
  opacity: 1;
}
.home-text21 {
  cursor: pointer;
  display: inline-block;
  transition: 0.1s;
}
.home-text21:hover {
  opacity: 1;
}
.home-text22 {
  display: inline-block;
}
.home-text22:hover {
  opacity: 1;
}
.home-text23 {
  display: inline-block;
}
.home-text23:hover {
  opacity: 1;
}
.home-text24 {
  display: inline-block;
}
.home-text25 {
  fill: #444f60;
  color: rgb(68, 79, 96);
  display: inline-block;
  font-size: 3.5rem;
  line-height: 1.2em;
  margin-bottom: 4.525856562845201%;
  animation-name: slideInLeft;
  letter-spacing: 1.2px;
  animation-delay: 0s;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-text26 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.home-text30 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.home-text34 {
  color: var(--dl-color-primary-lila);
  font-style: normal;
  font-weight: 700;
}
.home-text35 {
  fill: rgb(153, 153, 153);
  color: rgb(153, 153, 153);
  display: inline-block;
  letter-spacing: 1px;
}
.home-text36 {
  display: inline-block;
  font-size: 38px;
  font-family: "Open Sans";
  line-height: 1.6;
  text-transform: none;
  text-decoration: none;
}
.home-text39 {
  font-weight: 700;
}
.home-text40 {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.home-text41 {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.home-text42 {
  display: inline-block;
}
.home-text43 {
  display: inline-block;
}
.home-text44 {
  display: inline-block;
}
.home-text45 {
  display: inline-block;
}
.home-text46 {
  display: inline-block;
}
.home-text47 {
  display: inline-block;
  font-size: 22px;
}
.home-text48 {
  display: inline-block;
  font-size: 22px;
}
.home-text49 {
  display: inline-block;
  font-size: 22px;
}
.home-text50 {
  display: inline-block;
  font-size: 22px;
}
.home-text51 {
  display: inline-block;
}
.home-text52 {
  display: inline-block;
}
.home-text53 {
  display: inline-block;
}
.home-text54 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
}
.home-text55 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
}
.home-text56 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
}
.home-text57 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
}
.home-text58 {
  display: inline-block;
}
.home-text59 {
  display: inline-block;
}
.home-text60 {
  display: inline-block;
}
.home-text61 {
  display: inline-block;
}
@media(max-width: 479px) {
  .home-text36 {
    font-size: 28px;
  }
  .home-text37 {
    font-size: 28px;
    font-family: Open Sans;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 1px;
    text-transform: none;
    text-decoration: none;
  }
  .home-text39 {
    font-weight: 700;
  }
}
