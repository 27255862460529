.asked-questions-container1 {
  gap: 20px;
  flex: 1;
  width: 200px;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.asked-questions-quest1 {
  gap: 20px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: #fff;
}
.asked-questions-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.asked-questions-text1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.asked-questions-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.asked-questions-text2 {
  line-height: 1.6;
}
.asked-questions-quest2 {
  gap: 20px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: #fff;
}
.asked-questions-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.asked-questions-text3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.asked-questions-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.asked-questions-text4 {
  line-height: 1.6;
}
.asked-questions-quest3 {
  gap: 20px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: #fff;
}
.asked-questions-container6 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.asked-questions-text5 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.asked-questions-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.asked-questions-text6 {
  line-height: 1.6;
}
.asked-questions-quest4 {
  gap: 20px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 10px;
  background-color: #fff;
}
.asked-questions-container8 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.asked-questions-text7 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.asked-questions-container9 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.asked-questions-text8 {
  line-height: 1.6;
}

@media(max-width: 479px) {
  .asked-questions-text1 {
    line-height: 1.6;
  }
  .asked-questions-text3 {
    line-height: 1.6;
  }
  .asked-questions-text5 {
    line-height: 1.6;
  }
  .asked-questions-text7 {
    line-height: 1.6;
  }
  .asked-questionsroot-class-name {
    width: 100%;
  }
}
