.privacy-policy-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 90px;
  display: none;
  z-index: 100;
  box-shadow: 0px 2px 10px 0px rgba(212, 212, 212, 0.32);
  align-items: center;
  border-color: var(--dl-color-traffls-border);
  border-width: 1px;
  padding-left: 5%;
  padding-right: 5%;
  justify-content: space-between;
  background-color: #fff;
}
.privacy-policy-container12 {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.privacy-policy-container13 {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.privacy-policy-container14 {
  gap: 40px;
  flex: 1;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.privacy-policy-container15 {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.privacy-policy-link1 {
  display: contents;
}
.privacy-policy-container16 {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.privacy-policy-link2 {
  display: contents;
}
.privacy-policy-container17 {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.privacy-policy-link3 {
  display: contents;
}
.privacy-policy-container18 {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.privacy-policy-link4 {
  display: contents;
}
.privacy-policy-container19 {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.privacy-policy-link5 {
  display: contents;
}
.privacy-policy-container20 {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.privacy-policy-container21 {
  flex: 0 0 auto;
  width: 200px;
  height: 70px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.privacy-policy-link6 {
  display: contents;
}
.privacy-policy-container22 {
  text-decoration: none;
}
.privacy-policy-container23 {
  gap: 30px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  line-height: 1.6;
  padding-top: 80px;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: column;
  padding-bottom: 80px;
}
.privacy-policy-text107 {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-text108 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-container24 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text109 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-text110 {
  text-align: center;
}
.privacy-policy-container25 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text118 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text122 {
  text-align: center;
}
.privacy-policy-container26 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text126 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text127 {
  text-align: center;
}
.privacy-policy-container27 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text132 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text133 {
  text-align: center;
}
.privacy-policy-container28 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text137 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text138 {
  text-align: center;
}
.privacy-policy-text154 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-container29 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text155 {
  text-align: center;
}
.privacy-policy-container30 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text156 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text157 {
  text-align: center;
}
.privacy-policy-text165 {
  text-align: center;
}
.privacy-policy-container31 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text177 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text178 {
  text-align: center;
}
.privacy-policy-text186 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-container32 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text187 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text188 {
  text-align: center;
}
.privacy-policy-text194 {
  text-align: center;
}
.privacy-policy-text202 {
  text-align: center;
}
.privacy-policy-container33 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text208 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text209 {
  text-align: center;
}
.privacy-policy-text210 {
  text-align: center;
}
.privacy-policy-text218 {
  text-align: center;
}
.privacy-policy-text219 {
  text-align: center;
}
.privacy-policy-container34 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text225 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text226 {
  text-align: center;
}
.privacy-policy-container35 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text238 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text242 {
  text-align: center;
}
.privacy-policy-container36 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text268 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text271 {
  text-align: center;
}
.privacy-policy-container37 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text277 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text282 {
  text-align: center;
}
.privacy-policy-text304 {
  text-align: center;
}
.privacy-policy-container38 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text318 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text321 {
  text-align: center;
}
.privacy-policy-container39 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text330 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text331 {
  text-align: center;
}
.privacy-policy-container40 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text340 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text343 {
  text-align: center;
}
.privacy-policy-container41 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text352 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text353 {
  text-align: center;
}
.privacy-policy-text360 {
  text-align: center;
}
.privacy-policy-text366 {
  text-align: center;
}
.privacy-policy-text370 {
  text-align: center;
}
.privacy-policy-text376 {
  text-align: center;
}
.privacy-policy-text384 {
  text-align: center;
}
.privacy-policy-container42 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text394 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text395 {
  text-align: center;
}
.privacy-policy-text404 {
  text-align: center;
}
.privacy-policy-text408 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.privacy-policy-container43 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-text409 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.privacy-policy-text410 {
  text-align: center;
}
.privacy-policy-text417 {
  text-align: center;
}
.privacy-policy-text429 {
  text-align: center;
}
